/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.nav-with-dropdowns .menu-item-has-children > a').dropdown();

        $('body').on("click", function() {
            $('.nav-with-dropdowns .menu-item-has-children.open').removeClass('open');
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
        // Fire common init JS
          UTIL.fire('common');

          // Fire page-specific init JS, and then finalize JS
          $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
            UTIL.fire(classnm);
            UTIL.fire(classnm, 'finalize');
          });

          $(".menu-item-has-children").on("mouseenter", function() {
            $(this).addClass("open");
            $(this).find(".open").removeClass("open");
          });
          $(".menu-item-has-children").on("mouseleave", function() {
            $(this).removeClass("open");
            $(this).find(".open").removeClass("open");
            var s = $(this).find(".sub-menu");
            setTimeout(function() { s.collapse('hide');}, 500);

          });

          $(".navbar .cat-nav-item .sub-menu").collapse({
            toggle: false
          });

          $(".navbar .cat-nav-item").on("mouseenter", function() {
            $(this).find(".sub-menu").collapse('show');
          });
          $(".navbar .cat-nav-item").on("mouseleave", function() {
            var s = $(this).find(".sub-menu");
            setTimeout(function() { s.collapse('hide');}, 250);

          });


          $(".menu-item-has-children > .nav-link").on("touchstart", function(e) {
            e.stopPropagation();
            e.preventDefault();
            $(this).parents(".menu-item:first").siblings(".menu-item-has-children").removeClass("open");
            $(this).parents(".menu-item:first").toggleClass("open");
          });

          $(".overlay-container").on("touchstart", function() {
            if($(this).hasClass("hover")) {
                $(this).removeClass("hover").addClass("unhover");
            } else {
                $(this).addClass("hover");
                $(this).removeClass("unhover");
            }
          });
          $(".overlay-container").on("mouseenter", function() {
            $(this).addClass("hover").removeClass("unhover");
          });
          $(".overlay-container").on("mouseleave", function() {
            $(this).removeClass("hover").addClass("unhover");
          });


        var ctaForm = $(".products-nav");
        if(ctaForm.length > 0) {
            var ctaFormPosition = ctaForm.offset();
            var ctaFormTop = ctaFormPosition.top;
            var ctaFormLeft = ctaFormPosition.left;

            if(ctaFormTop) {

                $(window).scroll(function() {
                    var scroll = $(window).scrollTop();
                    var header = $(".banner");
                    var headerPosition = header.position();
                    var headerBottom = headerPosition.top + header.outerHeight();

                    if (scroll + headerBottom >= ctaFormTop) {
                        if(ctaForm.hasClass("sticky-cta-form")) {

                        } else {
                            var p = ctaForm.offset();
                            //var w = ctaForm.width();
                            ctaForm.css({top: Math.round(headerBottom)+'px', left: Math.round(p.left) + "px" }).addClass("sticky-cta-form");
                        }
                    } else {
                        if(ctaForm.hasClass("sticky-cta-form")) {
                            ctaForm.css({top: 'auto', left: 'auto' }).removeClass("sticky-cta-form");
                        }
                    }
                });
            }
            $(window).scroll();
        } else {

        }

          /*$('.gallery').each(function() {
            $(this).magnificPopup({
        		delegate: 'a',
        		type: 'image',
        		tLoading: 'Loading image #%curr%...',
        		mainClass: 'mfp-img-mobile',
        		gallery: {
        			enabled: true,
        			navigateByImgClick: true,
        			preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        		},
        		image: {
        			tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
        			titleSrc: function(item) {
        				return item.el.parents(".gallery-item").find(".gallery-caption").text();
        			}
        		}
        	});
        });*/

        $('a[href*=\\#]:not([href=\\#]):not([data-toggle])').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                if (target.length) {
                    $('html,body').animate({
                       scrollTop: this.hash==='#top' ? 0 : target.offset().top
                    }, 500);
                    $(this).parents(".navbar-collapse").removeClass("in");
                    return false;
                }
            }
        });


        // Fire common finalize JS
        UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


function initMap() {
    jQuery(".map").each(function() {
        var myLatLng = {lat: parseFloat(jQuery(this).attr('data-latitude')), lng: parseFloat(jQuery(this).attr('data-longitude'))};

        var map = new google.maps.Map(jQuery(this).get(0), {
            zoom: parseInt(jQuery(this).attr('data-zoom')),
            center: myLatLng
        });

        var marker = new google.maps.Marker({
            position: myLatLng,
            map: map,
            title: jQuery(this).attr('data-title')
        });
    });
}