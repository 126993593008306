zimco.controller('cartController', ['$scope', '$http', '$sce', function($scope, $http, $sce) {
    $scope.name = '';
    $scope.company = '';
    $scope.contact = '';
    $scope.message = '';

    $scope.loading = true;
    $scope.sending = false;
    $scope.sent = false;
    $scope.submitted = false;


    if($scope.ngDialogData) {
        $scope.products = $scope.ngDialogData.products;
    } else {
        $scope.products = {};
    }

    $scope.productsHtml = [];

    $scope.productsString = function() {
        var p = "";
        for(var i in $scope.products) {
            p += i + ',';
        }
        return p;
    };

    $scope.init = function() {

        $http({
            method  : 'GET',
            url     : ajaxurl + "?action=products&products=" + $scope.productsString(),
            headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(response){
            $scope.loading = false;
            $scope.productsHtml = response.data.products;
        });
    };

    $scope.product = function(i) {
        //console.log("product", i, $scope.productsHtml[i]);
        return $sce.trustAsHtml(i);
    };

    $scope.hasProducts = function() {
        for(var i in $scope.products) {
            return true;
        }
        return false;
    };

    $scope.removeItem = function(productId) {
        delete($scope.productsHtml[productId]);
        $scope.$root.$broadcast("remove-item", { id: productId});
    };

    $scope.send = function() {
        $scope.submitted =true;


        jQuery('.modal-dialog:visible .modal-body').animate({
           scrollTop: jQuery('.modal-dialog:visible .modal-body > div:first').height()
        }, 500);

        if(!$scope.frmCart.$valid) {
           return;
        }
        $scope.sending = true;

        $http({
            method  : 'POST',
            url     : ajaxurl,
            data    : jQuery.param({
                action: 'contact',
                products: $scope.productsString(),
                contact: $scope.contact,
                name: $scope.name,
                company: $scope.company,
                message: $scope.message
            }),
            headers : { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(function(response){
            $scope.sending = false;
            $scope.sent = true;
        });

    };

    $scope.print = function() {
        window.print();
    };

    $scope.init();
}]);
