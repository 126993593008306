zimco.controller('productListController', ['$scope', 'ngDialog', '$cookies', function($scope, ngDialog, $cookies) {
    $scope.cat_selected = 'all';
    $scope.brand_selected = 'all';
    $scope.products_selected = {};

    $scope.init = function() {
        // init
        $scope.products_selected = $cookies.getObject('zi');
        if(!$scope.products_selected) {
            $scope.products_selected = {};
        }
    };

    $scope.selectCat = function(cat_id) {
        $scope.cat_selected = cat_id;
    };
    $scope.selectBrand = function(cat_id) {
        $scope.brand_selected = cat_id;
    };

    $scope.saveItem = function(product_id) {
        if($scope.products_selected[product_id]) {
            delete($scope.products_selected[product_id]);
        } else {
            $scope.products_selected[product_id] = true;
        }
        $cookies.putObject('zi',$scope.products_selected, { path: '/' });
    };

    $scope.$on("remove-item", function(e, args) {
        $scope.removeItem(args.id);
    });

    $scope.removeItem = function(product_id) {
        if($scope.products_selected[product_id]) {
            delete($scope.products_selected[product_id]);
            $cookies.putObject('zi',$scope.products_selected, { path: '/' });
        }
    };

    $scope.hasBrandProducts = function(product_ids) {
        if($scope.brand_selected === 'all' ) {
            return true;
        }
        if(product_ids.indexOf($scope.brand_selected + ',') > -1) {
            return true;
        }
        return false;
    };

    $scope.hasCatProducts = function(product_ids) {
        if($scope.cat_selected === 'all' ) {
            return true;
        }
        if(product_ids.indexOf($scope.cat_selected + ',') > -1) {
            return true;
        }
        return false;
    };

    $scope.itemSaved = function(product_id) {
        if($scope.products_selected[product_id]) {
            return true;
        }
        return false;
    };

    $scope.hasProducts = function() {
        for(var i in $scope.products_selected) {
            return true;
        }
        return false;
    };

    $scope.viewCart = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        ngDialog.open({
            template: 'cartTemplate',
            controller: 'cartController',
            data: {
                products: $scope.products_selected
            },
            disableAnimation: true,
            showClose: false
        });
    };
}]);